import React from "react"
import ctl from "@netlify/classnames-template-literals"

const Flex = ({ firstItem, secondItem, reverseMobile, className, alignTop, firstItemClassName, secondItemClassName, isAtTopOfPage, justifyCenter, justifyStart }) => {
  let justifyClass = 'justify-between';

  if(justifyCenter){
    justifyClass = 'justify-center'
  }

  if(justifyStart){
    justifyClass = 'justify-start'
  }

  const flexStyle = ctl(
    `flex flex-wrapper font-hkgrotesk lg:text-left text-center 
    lg:flex-row  ${justifyClass} md:text-xl 
    text-[16px] md:pt-[90px] ${isAtTopOfPage ? 'pt-[40px]' : 'pt-[60px]'} md:w-auto md:w-11/12 
    mx-auto lg:even:flex-row items-center
    ${alignTop && 'lg:items-start'}
    ${
      reverseMobile
        ? "flex-col-reverse even:flex-col"
        : "flex-col even:flex-col-reverse"
    }

    ${className}
    `
  )

  const firstItemStyle = ctl(` lg:min-w-[550px] md:mx-0 mx-auto ${firstItemClassName}`)
  const secondItemStyle = ctl(`md:w-auto lg:mt-0 mt-12  ${secondItemClassName}`)

  return (
    <div className={flexStyle}>
      <div className={firstItemStyle}>{firstItem}</div>
      <div className={secondItemStyle}>{secondItem}</div>
    </div>
  )
}

export default Flex
