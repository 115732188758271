import React from "react"
import Container from "../container"
import Button from "../button"
import { StaticImage } from "gatsby-plugin-image"
import ctl from "@netlify/classnames-template-literals"
import { DesktopBreakpoint } from "../../breakpoints"

const ReceiveTradingSupport = ({ withMargin, isHome, className  }) => {
  const wrapperStyle = ctl(
    `md:overflow-hidden relative
    lg:text-left text-center ${withMargin ? 'md:mt-[100px]' : 'md:mt-0'} ${withMargin ? 'mt-[30px]' : isHome ? 'mt-[80px]' : 'mt-[60px]'} ${className}`
  )

  const containerStyle = ctl(
    `flex justify-between lg:items-start items-center lg:flex-row flex-col md:pl-[110px] md:pr-[36px]`
  )

  const textWrapperStyle = ctl(`md:w-6/12 sm:w-8/12 lg:w-4/12 w-11/12`)

  const headingStyle = ctl(`md:text-[38px] text-[34px] leading-[44px] md:leading-[1.18] font-extrabold`)

  const paragraphStyle = ctl(`
    text-base md:mx-0 mx-auto mb-[20px] md-max:mt-[25px]
    font-medium md-max:leading-[25px]
  `)

  const imageStyle = ctl(
    `md:w-8/12 w-11/12 lg:w-[620px] lg:mt-0 ${isHome ? "mt-[65px] md:mt-[100px]" : "mt-[70px] md:mt-[100px]"}`
  )

  return (
    <section className={wrapperStyle}>
      {/*{!isHome && (<img src={patternImageMobile} alt="" className='md:hidden w-[100vw] bottom-[-121px] absolute left-0'/>)}*/}
      <Container size='header' className={containerStyle}>
        <div className={textWrapperStyle}>
          <h2 className={headingStyle}>
            Quality Data.
            <br />
            Intelligent Choices.
            <br />
            Profitable Trades.
          </h2>
          <p className={paragraphStyle}>
            Find news, analysis & educational resources designed <DesktopBreakpoint />
            to empower you with actionable insights at the right<DesktopBreakpoint />
            time for profitable trades in the financial markets.
          </p>
          <Button
            type="tertiary"
            label={
              <>
                <span className='md-max:hidden'>Tryindexa.com</span>
                <span className='md:hidden'>tryindexa.com</span>
              </>
            }
            url="#"
            isExternal
            hasArrow
            className='!text-lg md-max:!py-0'
            hasMargin={false}
          />
        </div>

        <div className={imageStyle}>
          <StaticImage
            src="../../../assets/images/components/trading-support/Indexa Devices.webp"
            placeholder="none"
          />
        </div>
      </Container>
    </section>
  )
}

ReceiveTradingSupport.propTypes = {}

export default ReceiveTradingSupport
