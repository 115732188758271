import React from "react"
import Flex from "../../../utils/flex"
import { StaticImage } from "gatsby-plugin-image"
import ctl from "@netlify/classnames-template-literals"
import ButtonGroup from "../../../utils/button/group"
import { Container } from "../../../utils"

const Mt4PlatformChart = () => {
  return (
    <div className="md-max:hidden bg-light-tangerine mt-[100px] md:pt-[83px] md:pb-[85px] border-t border-b border-brownish-grey">
      <Container size="large" className="md:pl-[90px]">
        <Flex
          alignTop
          className="md:!pt-0 md-max:hidden md:w-full"
          firstItem={
            <StaticImage
              src="../../../../assets/images/pages/mt4/mt4-chart.webp"
              alt="Metatrader 4 chart"
              className={imageStyle}
            />
          }
          secondItem={
            <div className={infoWrapperStyle}>
              <p className="mt-0 md:min-w-[420px]">
                With 40 FX pairs, 13 Indices and 13 Commodities as well<br />
                as Global Equities Egmarkets’ MT4 has a market- <br />
                leading range of products to trade 24 hours 5 days a week.
              </p>

              <ButtonGroup className='md:!max-w-[400px]' buttons={buttons} />
            </div>
          }
          reverseMobile
        />
      </Container>
    </div>
  )
}

const buttons = [
  {
    label: "Open an MT4 Account",
    isExternal: true,
    url: "https://clients.eagleglobalmarkets.com/#/step-one"
  },
  {
    label: "Download MT4 on Windows",
    isExternal: true,
    url: "/eaglegm4setup.exe",
    className: 'bg-transparent'
  }
]

const infoWrapperStyle = ctl(`max-w-[500px] mt-[65px] flex flex-col items-center lg:items-start mb-12`)
const imageStyle = ctl(`lg:mt-0 mt-[60px] max-w-[505px] md:left-[-28px]`)

export default Mt4PlatformChart
