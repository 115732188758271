import React from "react"
import ctl from "@netlify/classnames-template-literals"
import PropTypes from "prop-types"

import Button from "."

const ButtonGroup = ({ buttons, fullWidthOnMobile = false, className }) => {
  const buttonGroupStyle = ctl(`${fullWidthOnMobile ? 'md-max:max-w-[300px] md-max:mx-auto' : 'md-max:px-[30px]'} button-group md:text-left text-center mt-[30px] ${className}`)

  return (
    <div className={buttonGroupStyle}>
      <Button {...buttons[0]} inGroup />
      <Button {...buttons[1]} type="secondary" inGroup />
    </div>
  )
}

ButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
}

ButtonGroup.defaultProps = {
  buttons: [
    {
      label: "First button",
      url: "#",
    },
    {
      label: "Second Button",
      url: "#",
    },
  ],
}

export default ButtonGroup
