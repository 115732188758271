import React from "react"
import Container from "../../../utils/container"

import ctl from "@netlify/classnames-template-literals"
import { StaticImage } from "gatsby-plugin-image"
import AppstoreButtons from "./appstore-buttons"
import "./index.css"
import { DesktopBreakpoint } from "../../../breakpoints"

const PlatformAppDownload = ({ page, appleUrl, googleUrl }) => {
  const pageLowercase = page.toLowerCase()
  return (
    <div className={containerWrapperStyle}>
      <div className={rectangleStyle} />
      <Container size="medium">
        <section className={containerStyle}>
          <div>
            {pageLowercase === "eagletrade" && (
              <StaticImage
                src="../../../../assets/images/pages/eagletrade/eagletrade-app-mockup.webp"
                alt="Cloud Trade App Mockup"
                className={mockupStyle}
              />
            )}
            {pageLowercase === "mt4" && (
              <StaticImage
                src="../../../../assets/images/pages/mt4/mt-mock-new.webp"
                alt="Cloud Trade App Mockup"
                className={mockupStyle}
              />
            )}
          </div>
          <div className={infoWrapperStyle}>
            <h3 className={headingStyle}>
              Download The {page} <DesktopBreakpoint />
              Mobile Application
            </h3>
            <AppstoreButtons page={page} appleUrl={appleUrl} googleUrl={googleUrl} />
          </div>
        </section>
      </Container>
    </div>
  )
}

const containerWrapperStyle = ctl(`relative`)

const rectangleStyle = ctl(`
  md:h-[76px] h-[663px] absolute top-[110px] w-full bg-light-tangerine -z-1 left-0 border-t border-b border-brownish-grey
`)

const infoWrapperStyle = ctl(`md:w-6/12 w-full`)
const mockupStyle = ctl(
  `md:left-[116px] max-w-[284px] 
  transform top-[50%] md:mx-0 mx-auto md:mt-0 mt-[98px] 
  md:translate-y-[-50%] md:!absolute`
)
const containerStyle = ctl(
  `bg-deep-blue max-w-[966px] flex justify-end rounded-[15px]
  pt-[50px] md:pt-[40px] pb-[72px]
  md:!px-[116px] px-[44px]
  mt-[117px] md:mt-[140px] md:mb-0 
  relative flex md:flex-row flex-col-reverse md-max:mb-0
  md-max:pb-[100px]
  md-max:mb-[160px]
  container
  items-center
  md-max:max-w-[400px]
  md-max:mx-auto
  `
)

const headingStyle = ctl(`
md-max:font-normal text-[30px] leading-[1.33] 
md:text-left text-center text-white font-semibold
`)

export default PlatformAppDownload
