import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ButtonGroup from "../../../utils/button/group"

const buttons = [
  {
    label: "Open an MT4 Account",
    isExternal: true,
    url: "https://clients.eagleglobalmarkets.com/#/step-one",
    className: 'md-max:flex md-max:text-sm md-max:items-center md-max:justify-center md-max:h-[50px]'
  },
  {
    label: "Download MT4 on Windows",
    isExternal: true,
    url: "/eaglegm4setup.exe",
    className: 'bg-transparent md-max:text-sm md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px]'
  },
]

const Mt4PlatformChartMobile = () => {
  return (
    <div className='md:hidden mb-[95px] pt-[40px] mt-[-30px] pb-[72px] bg-light-tangerine border-t border-b border-brownish-grey'>
      <div className='flex pr-[33px] gap-x-[20px] pb-[20px]'>
        <StaticImage
            src="../../../../assets/images/pages/mt4/mt4-chart-mobile.webp"
          alt="Metatrader 4 chart"
          className='w-[155px] flex-shrink-0'
        />
        <p className='mt-[52px] text-base'>
          With 40 FX pairs, 13 Indices and 13 Commodities as well as Global Equities Egmarkets’ MT4 has a market-leading range of products to trade 24 hours 5 days a week.
        </p>
      </div>
      <ButtonGroup className='md-max:gap-y-[20px]' fullWidthOnMobile buttons={buttons} />
    </div>
  )
}

export default Mt4PlatformChartMobile