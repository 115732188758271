import React from "react"
import ctl from "@netlify/classnames-template-literals"
import Container from "../../../utils/container"
import Mt4Hero from "../../../animations/mt4-hero"

const Mt4PlatformIntro = () => {
  return (
    <Container size="large" className="md-max:overflow-hidden">
      <section className={sectionStyle}>
        <article className={contentWrapperStyle}>
          <h1 className="md-max:text-center md:text-[40px] md:leading-[1.25]">Metatrader 4</h1>
          <p className="mt-[24px] md-max:hidden">
            Discover the benefits of trading with Egmarkets on one <br/>
            of the world’s most powerful trading platforms.
          </p>
          <p className="mt-[50px] md-max:hidden">
            Experience seamless trading, and get express access to <br />
            advanced charting tools, numerous technical indicators, <br />
            real-time price charts, market insights, and a suite of <br />
            automated trading options.
          </p>

          <p className="mt-[30px] whitespace-pre-wrap md:hidden text-center">
            Discover the benefits of trading
            with Egmarkets on one of the world’s
            most powerful trading platforms.
          </p>
          <p className="mt-[24px] whitespace-pre-wrap md:hidden text-center mb-10">
            Experience seamless trading, and get express
            access to advanced charting tools, numerous
            technical indicators, real-time price charts, market
            insights, and a suite of automated trading options.
          </p>
        </article>
        <figure className={imageStyle}>
          <Mt4Hero/>
        </figure>
      </section>
    </Container>
  )
}

const sectionStyle = ctl(
  "md-max:flex-col flex font-hkgrotesk items-start pt-[40px] md:pt-[80px] justify-between md:text-xl text-[16px]"
)

const imageStyle = ctl(`
shape lg:w-[603px] z-10
md:top-[95px] md:right-0 md:mx-auto
md-max:relative w-[93.7vw]
md-max:max-w-none md-max:right-[-24px]
`)

const contentWrapperStyle = ctl(`
  text-left md:pl-[29px]
  md:w-auto lg:min-w-[550px]
`)
export default Mt4PlatformIntro
