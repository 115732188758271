import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import ctl from "@netlify/classnames-template-literals"

const AppstoreButtons = ({ appleUrl, googleUrl, page }) => {
  return (
    <div className={logosWrapperStyle}>
      <a href={appleUrl} target='_blank' rel='noreferrer'>
        <StaticImage
          src="../../../../assets/logo/apple-download.webp"
          className={logoStyle}
          alt={`Download ${page} app`}
        />
      </a>
      <a href={googleUrl} target='_blank' rel='noreferrer'>
        <StaticImage
          src="../../../../assets/logo/googleplay-download.webp"
          className={logoStyle}
          alt={`Download ${page} app`}
        />
      </a>
    </div>
  )
}

export default AppstoreButtons

const logosWrapperStyle = ctl(`
  flex justify-between
  md-max:flex-col gap-y-[24px] md:gap-y-[30px]
  mt-[78px] md:mt-[30px] mx-auto max-w-[340px]
  items-center
`)
const logoStyle = ctl(`md:max-w-[158px] max-w-[208px]`)
