import * as React from "react"
import Layout from "../../components/layout"
import PlatformAppDownload from "../../components/pages/platforms/app-download"
import Mt4PlatformChart from "../../components/pages/platforms/mt4/chart"
import Mt4PlatformIntro from "../../components/pages/platforms/mt4/intro"
import ReceiveTradingSupport from "../../components/utils/trading/support"
import Mt4PlatformChartMobile from "../../components/pages/platforms/mt4/chart-mobile"

const Mt4PlatformPage = () => (
  <Layout
    title="Egmarkets | MetaTrader 4 - Download MT4"
    ignoreSiteName
    disableOverflow
    description='Trade the financial markets with the most popular Forex trading tool - Meta Trader 4 on our platform.'
    meta={[
        {
            name: 'Open an MT4 account (link in page)',
            content: 'Get started with MT4 | Egmarkets'
        },
        {
            name: 'Download MT4 (link in page)',
            content: 'Download MT4 on Windows'
        },
    ]}
  >
    <Mt4PlatformIntro />
    <Mt4PlatformChart />
    <Mt4PlatformChartMobile />
    <ReceiveTradingSupport withMargin/>
    <PlatformAppDownload
      appleUrl='https://apps.apple.com/ng/app/metatrader-4/id496212596'
      googleUrl='https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4'
      page="MT4"
    />
  </Layout>
)

export default Mt4PlatformPage
